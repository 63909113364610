var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "signin", dark: "" } },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            {
              staticClass: "sign-in-page fill-height pa-0",
              attrs: { fluid: "" },
            },
            [
              _c(
                "v-row",
                { staticClass: "fill-height", attrs: { "no-gutters": "" } },
                [
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c(
                        "v-col",
                        {
                          staticClass: "left-part",
                          style: {
                            backgroundImage: `url('${_vm.background}')`,
                          },
                        },
                        [
                          _c("h1", { staticClass: "main-title" }, [
                            _vm._v(" La "),
                            _c("span", [_vm._v("plateforme data")]),
                            _c("br"),
                            _vm._v(" conçue pour le retail "),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "right-part" },
                      [
                        _c("img", {
                          staticClass: "mt-10",
                          staticStyle: { width: "200px" },
                          attrs: {
                            src: require("@/assets/img/app/logo-v2-dark.svg"),
                            alt: "Tailer Logo",
                          },
                        }),
                        _c("h1", { staticClass: "mt-16 font-weight-bold" }, [
                          _vm._v("Welcome back,"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass:
                              "mt-2 font-weight-light grey--text text--darken-3",
                          },
                          [
                            _vm._v(
                              " Continue with Google, Microsoft or enter your details. "
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "google-button mt-4",
                            attrs: { type: "button" },
                            on: { click: _vm.googleSignIn },
                          },
                          [
                            _c("span", { staticClass: "icon" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    viewBox: "0 0 366 372",
                                    xmlns: "http://www.w3.org/2000/svg",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z",
                                      id: "Shape",
                                      fill: "#EA4335",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z",
                                      id: "Shape",
                                      fill: "#FBBC05",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z",
                                      id: "Shape",
                                      fill: "#4285F4",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z",
                                      fill: "#34A853",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v("Log in with Google"),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "divider" }, [
                          _c("div", { staticClass: "line" }),
                          _c("p", { staticClass: "text" }, [_vm._v("or")]),
                          _c("div", { staticClass: "line" }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "form" },
                          [
                            _c(
                              "v-form",
                              {
                                ref: "form",
                                model: {
                                  value: _vm.valid,
                                  callback: function ($$v) {
                                    _vm.valid = $$v
                                  },
                                  expression: "valid",
                                },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    name: "login",
                                    label: "Login",
                                    id: "Email",
                                    type: "email",
                                    rules: _vm.emailRules,
                                    "background-color": "#F6F6F6",
                                    required: "",
                                    solo: "",
                                    "data-cy": "signInEmailField",
                                  },
                                  model: {
                                    value: _vm.model.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "email", $$v)
                                    },
                                    expression: "model.email",
                                  },
                                }),
                                _c("v-text-field", {
                                  attrs: {
                                    name: "password",
                                    label: "Password",
                                    id: "password",
                                    type: "password",
                                    rules: _vm.passwordRules,
                                    required: "",
                                    "background-color": "#F6F6F6",
                                    solo: "",
                                    "data-cy": "signInPasswordField",
                                  },
                                  model: {
                                    value: _vm.model.password,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "password", $$v)
                                    },
                                    expression: "model.password",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "w-full text-right" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "ma-0 black--text text-body-2",
                                        attrs: { to: _vm.resetPasswordLink },
                                      },
                                      [_vm._v(" Forgot password ")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "white--text w-full mt-10 button",
                                    attrs: {
                                      disabled: !_vm.valid,
                                      loading: _vm.loading,
                                      color: "#111728",
                                      width: "100%",
                                      height: "40px",
                                    },
                                    on: { click: _vm.signIn },
                                  },
                                  [_vm._v(" Login ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.userNotAuthorized
                          ? _c(
                              "v-alert",
                              { staticClass: "my-5", attrs: { type: "error" } },
                              [_vm._v(" Sorry, cannot authorize user ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }