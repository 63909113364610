
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { Component, Vue } from 'vue-property-decorator';
import { InputValidationRules } from 'vuetify';
import { HOME, RESET_PASSWORD } from '@/constants/router/routes-names';
import { KEY } from '@/constants/store/vuex-persist';

import background from '@/assets/img/sign-in/background.png';

type Model = {
	email: string;
	password: string;
};

@Component({
	props: {
		source: String,
	},
})
export default class SignIn extends Vue {
	valid: boolean = false;
	userNotAuthorized: boolean = false;
	loading: boolean = false;
	model: Model = { email: '', password: '' };
	emailRules: InputValidationRules = [
		(v) => !!v || 'E-mail is required',
		(v) => /.+@.+/.test(v) || 'E-mail must be valid',
	];
	passwordRules: InputValidationRules = [
		(v) => !!v || 'Password is required',
		(v) => v.length >= 6 || 'Password must be greater than 6 characters',
	];

	get background(): File {
		return background;
	}

	mounted() {
		localStorage.removeItem(KEY);
	}

	signInCallback(payload: () => void) {
		const auth = getAuth();
		setPersistence(auth, browserLocalPersistence)
			.then(payload)
			.catch((error) => console.error(error));
	}

	googleSignIn() {
		this.userNotAuthorized = false;
		this.loading = true;

		this.signInCallback(() => {
			this.$store
				.dispatch('user/googleSignIn')
				.then(() => this.successCallback())
				.catch(() => this.errorCallback());
		});
	}

	signIn() {
		this.userNotAuthorized = false;
		this.loading = true;

		if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			this.signInCallback(() => {
				this.$store
					.dispatch('user/signIn', { email: this.model.email, password: this.model.password })
					.then(() => this.successCallback())
					.catch(() => this.errorCallback());
			});
		}
	}

	successCallback() {
		this.loading = false;
		this.$router.push(this.$route.query.redirect?.toString() || { name: HOME });
	}

	errorCallback() {
		this.loading = false;
		this.userNotAuthorized = true;
	}

	get resetPasswordLink() {
		return { name: RESET_PASSWORD };
	}
}
